<template>
  <div class="legal">
    <div class="legal-types">
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="4" v-for="legal in legals" :key="legal.key">
          <v-hover v-slot="{ hover }">
            <v-card
              class="qrcode-type-card"
              :class="{ 'on-hover': hover }"
              :elevation="hover ? 6 : 2"
              :to="{ name: legal.to }"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-avatar size="60">
                  <v-icon class="lighten-1" :class="hover ? 'primary' : 'grey'" size="28" dark>
                    {{ legal.icon }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="headline"> {{ legal.label }} </v-list-item-title>
                  <v-list-item-subtitle> Lorem ipsum dolor sit amet. </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Legal",

  middleware: "auth",

  metaInfo() {
    return { title: "Legal" };
  },

  computed: {
    legals() {
      return [
        {
          key: "terms-and-conditions",
          icon: "mdi-text-box-check-outline",
          label: this.$t("Legal.TermsAndConditions"),
          to: "legal.terms-and-conditions",
        },
        {
          key: "cookies-policy",
          icon: "mdi-book-lock-open-outline",
          label: this.$t("Legal.CookiesPolicy"),
          to: "legal.cookies-policy",
        },
        {
          key: "privacy-policy",
          icon: "mdi-text-box-check-outline",
          label: this.$t("Legal.PrivacyPolicy"),
          to: "legal.privacy-policy",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.legal {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 125px);
}

@media screen and (max-width: 576px) {
  .legal {
    padding-top: 60px;
  }
}
</style>
